/* style.css */

/* Style for the container div */

  
  /* Style for the main content */
  .privacy-content {
    font-family: Arial, sans-serif;
    font-size: 16px;
    line-height: 1.6;
    color: #333;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
  }
  