
.Laboratories .container{
  max-width: 1100px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 20px;
}
.Laboratories .container .box{
  width: calc(33% - 10px);
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 30px;
  border-radius: 5px;
}
.Laboratories .box .quote i{
margin-top: 10px;
font-size: 45px;
color: #17c0eb
}
.Laboratories .container .box .image{
  margin: 10px 0;
  height: 150px;
  width: 150px;
  background: #0e2aa7;
  padding: 3px;
  border-radius: 50%;
}
.Laboratories .box .image img{
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
}
.Laboratories .box p{
  text-align: justify;
  margin-top: 8px;
  font-size: 16px;
  font-weight: 400;
}
.Laboratories .box .name_job{
  margin: 10px 0 3px 0;
  color: #0e2aa7;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}
.Laboratories .box .username{
  margin: 10px 0 3px 0;
  color: #515c8d;
  font-size: 18px;
  font-weight: 600;
}
.Laboratories .rating i{
  font-size: 18px;
  color: #0e2aa7;
  margin-bottom: 5px;
}
.Laboratories .btns{
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.Laboratories .btns button{
  background: #0e2aa7;
  width: 100%;
  padding: 9px 0px;
  outline: none;
  border: 2px solid #0e2aa7;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  color: #0e2aa7;
  transition: all 0.3s linear;
}
.Laboratories .btns button:first-child{
  background: none;
  margin-right: 5px;
}
.Laboratories .btns button:last-child{
  color: #fff;
  margin-left: 5px;
}
.Laboratories .btns button:first-child:hover{
  background: #0e2aa7;
  color: #fff;
}
.Laboratories .btns button:hover{
  color: #fff;
}
.Laboratories .find{
  text-align: center;
  margin-top: 100px;
  margin-bottom: 20px;
  font-size: 50px;
}

@media (max-width:1045px){
  .Laboratories .container .box{
    width: calc(50% - 10px);
    margin-bottom: 20px;
  }
}
@media (max-width:710px){
  .Laboratories .container .box{
    width: 100%;
  }
}

