.hero {
  background-image: url("../../../../public/images/bg.webp");
  background-size: cover;
  background-attachment: fixed;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100vh;
  padding-top: 20%;
  padding-right: 50px;
  margin-bottom: 50rem;
  color: #fff;
}
.hero #heading h3 {
  color: #fff;
}
.href{
  cursor: pointer;
}
.hero #heading {
  text-align: left;
  margin: 0;
  padding: 0;
}
.margin {
  height: 10vh;
}



@media screen and (max-width: 1200px) {
  .margin {
    height: 40vh;
  }
  .row{
    margin-top: 10rem;
  }
}
@media screen and (max-width: 900px) {
  .margin {
    height: 43vh;
  }
}
@media screen and (max-width: 768px) {
  .margin {
    height: 40vh;
  }
  .row{
    margin-top: 0;
  }
  .hero {
    background-position: right;
    padding-top: 50%;
    height: 80vh;
  }
  .hero .row {
    width: 100%;
  }
  .hidden{
    display: none;
  }
}
