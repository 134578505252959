.testimonal .items {
  padding: 30px;
  transition: 0.5s;
      width: 100%;
}
.testimonal .container .flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.testimonal .img {
  position: relative;
}
.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1e39b2;
  color: #fff;
}
.testimonal .name {
  margin: 25px;
}
.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal .name span {
  color: #1e39b2;
}
.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #1e39b2;
  color: #fff;
  cursor: pointer;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #fff;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #1e39b2;
}

.content{
  width: 100%;
}

@media (max-width: 1000px) {
  .testimonal .container .flex {
    flex-direction: column;
    width: 100%;

  }
  .testimonal .items {
    width: 100%;
    margin-bottom: 20px;
  }
  .testimonal .content {
    grid-template-columns: 1fr;
  }
  .testimonal .name h2 {
    font-size: 18px;
  }
  .testimonal .name span {
    font-size: 14px;
  }
  .testimonal p {
    font-size: 14px;
  }
}


@media (max-width: 480px) {
  .testimonal .name h2 {
    font-size: 18px;
  }
  .testimonal img {
    width: 80px;
    height: 80px;
  }
  .testimonal .name {
    margin: 15px;
  }
}
