.article .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .article .mb-8 {
    margin-bottom: 2rem;
  }
  
  .article .p-8 {
    padding: 2rem;
  }
  
 
  
  
  
  .article .rounded-lg {
    border-radius: 0.5rem;
  }
  
  .article .hover\:shadow-lg:hover {
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .article .transition-all {
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  

  .article .flex {
    display: flex;
  }
  
  .article .justify-between {
    justify-content: space-between;
  }
  
  .article .flex-col {
    flex-direction: column;
  }
  
  .article .md\:flex-row {
    flex-direction: row;
  }
  
  .article .items-center {
    align-items: center;
  }
  
  .article .mb-5 {
    margin-bottom: 1.25rem;
  }
  
  .article .font-semibold {
    font-weight: 600;
  }
  
  .article .mt-4 {
    margin-top: 1rem;
  }
  
  .article .text-3xl {
    font-size: 1.875rem;
  }
  
  .article .font-bold {
    font-weight: 700;
  }
  
  .article .mb-3 {
    margin-bottom: 0.75rem;
  }
  
  .article .overflow-hidden {
    overflow: hidden;
  }
  
  .article .h-64 {
    height: 20rem;
  }
  
  .article .w-full {
    width: 100%;
  }
  
  .article .h-full {
    height: 100%;
  }
  
  .article .object-contain {
    object-fit: contain;
  }
  
  .article .rounded-md {
    border-radius: 0.375rem;
  }
  
  .article .mb-6 {
    margin-bottom: 1.5rem;
  }
  

  .commentContainer {
    margin-top: 6px;
    background-color: #d3d3d3e0;
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    width: 300px;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .commentContainer:hover {
    background-color: #d3d3d3bf;
  }
  
  .inputContainer {
    display: flex;
    align-items: baseline;
    gap: 5px;
  }
  
  .inputContainer > span {
    margin-top: 5px;
  }
  
  .inputContainer__input {
    margin: 6px 0 0px 0;
    padding: 5px;
    display: flex;
    border: 1px solid lightgray;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    border-radius: 5px;
    background-color: #e7e7e7;
  }
  
  .first_input {
    margin: 0;
  }
  
  .commentContainer > span {
    margin: 0 5px;
  }
  
  .reply {
    font-size: 12px;
    padding: 5px;
    border-radius: 5px;
    color: #4e4e4e;
    font-weight: 600;
    cursor: pointer;
  }
  
  .comment {
    color: #ffffff;
    background-color: #569dff;
    letter-spacing: 0.8px;
  }
