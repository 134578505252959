.Post {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: var(--cardColor);
    border-radius: 1rem;
    gap: 1rem;
}

.postContent {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.Post img.postImage {
    width: 30%;
    height: 20rem; /* Fixed height */
    object-fit: contain;
    border-radius: 0.5rem;
}

.detail {
    width: 70%;
    display: flex;
    gap: 15px;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 25px;
}

.detail .title {
    font-size: 25px;
}

.postReact {
    display: flex;
    align-items: center;
    gap: 1.5rem;
}

.postDescription {
    color: rgb(83, 83, 83);
}

.titlehref {
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    color: black;
}
.titlehref .edit{
    font-size: 25px;
    color: blue;

}
.titlehref .deletebutton{
    font-size: 25px;
    color: red;
    margin-left: 5px;
}
.readMore {
    font-size: 30px;
}

.postReact .rounded-lg {
    border-radius: 0.5rem; /* Adjust as needed */
}

.postReact .border {
    cursor: pointer;
    border-width: 1px; /* Adjust as needed */
}

.postReact .border-violet-500 {
    border-color: #8b5cf6; /* Adjust as needed */
}

.postReact .bg-violet-500 {
    background-color: #8b5cf6; /* Adjust as needed */
}

.postReact .px-5 {
    padding-left: .5rem; /* Adjust as needed */
    padding-right: .5rem; /* Adjust as needed */
}

.postReact .text-center {
    text-align: center;
}

.postReact .text-sm {
    font-size: 0.875rem; /* Adjust as needed */
}

.postReact .font-medium {
    font-weight: 500; /* Adjust as needed */
}

.postReact .text-white {
    color: #ffffff; /* Adjust as needed */
}

.postReact .shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* Adjust as needed */
}

.postReact .transition-all {
    transition: all 0.3s ease-in-out; /* Adjust as needed */
}

.postReact .hover\:border-violet-700:hover {
    border-color: #6d28d9; /* Adjust as needed */
}

.postReact .hover\:bg-violet-700:hover {
    background-color: #6d28d9; /* Adjust as needed */
}

.postReact .focus\:ring:focus {
    box-shadow: 0 0 0 3px rgba(139, 92, 246, 0.5); /* Adjust as needed */
}

.postReact .focus\:ring-violet-200:focus {
    box-shadow: 0 0 0 3px rgba(233, 213, 255, 0.5); /* Adjust as needed */
}

.postReact .disabled\:cursor-not-allowed:disabled {
    cursor: not-allowed;
}

.postReact .disabled\:border-violet-300:disabled {
    border-color: #d8b4fe; /* Adjust as needed */
}

.postReact .disabled\:bg-violet-300:disabled {
    background-color: #d8b4fe; /* Adjust as needed */
}

.postReact .py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

/* Media queries for medium and small screens */
@media (max-width: 1536px) {
    .postContent {
        flex-direction: column;
        align-items: center;
    }
    
    .Post img.postImage {
        width: 100%;
        height: 15rem; /* Fixed height for smaller screens */
    }
    
    .detail {
        width: 100%;
        margin-top: 0;
    }
}


@media (max-width: 768px) {
    .Post{
        margin-bottom: 40px;

    }
}
