.pointer {
    cursor: pointer;
    text-decoration: none;
    text-transform: none;
    color: black;
}

@media screen and (max-width: 1024px) {
    .navIcons {
        bottom: 0; /* Adjusted from 30px to 0 to place it at the bottom */
        left: 0; 
        right: 0; 
        position: fixed;
        background: #fff;
        padding: 1rem;
        box-shadow: 10px 5px 5px black;       
    }
}
