.Home {
  position: relative;
  display: grid;
  grid-template-columns: 18rem auto 20rem;
  gap: 1rem;
}

@media (max-width: 1200px) {
  .Home{
    grid-template-columns: 17rem auto 15rem;
  }
}


@media (max-width: 1050px) {
  .Home{
    grid-template-columns: 20rem auto ;
  }
}

@media (max-width: 768px) {
  .Home {
    grid-template-columns:  auto  ;
  }
}