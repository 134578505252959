.price .items {
  padding: 40px;
  text-align: center;
  box-sizing: border-box; /* Ensure padding is included in width calculations */
}
.price h1 {
  font-size: 50px;
  margin: 20px 0;
  color: #1e39b2;
}
.price h1 span {
  font-size: 20px;
  margin-right: 5px;
  color: #000;
}
.price p {
  color: grey;
  margin: 40px 0;
}
.price .outline-btn {
  padding: 10px 20px;
  border: 2px solid #1e39b2;
  background-color: transparent;
  color: #1e39b2;
  cursor: pointer;
  transition: all 0.3s ease;
}
.price .outline-btn:hover {
  background-color: #1e39b2;
  color: #fff;
}

@media (max-width: 768px) {
  .price .items {
    padding: 20px;
  }
  .price h1 {
    font-size: 30px;
  }
  .price h1 span {
    font-size: 16px;
  }
  .price p {
    margin: 20px 0;
  }
}

@media (max-width: 480px) {
  .price .items {
    padding: 10px;
  }
  .price h1 {
    font-size: 24px;
  }
  .price h1 span {
    font-size: 14px;
  }
  .price p {
    margin: 10px 0;
  }
}

/* Container and grid adjustments */
.container {
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}
.faq .container {
  max-width: 70%;
  border-radius: 5px;
}
.faq .box {
  background-color: #fff;
  margin-bottom: 20px;
}
.faq button {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 15px 20px;
}
.faq h2 {
  font-weight: 500;
}
.faq p {
  padding: 20px;
}
.faq .accordion:hover {
  background-color: #1e34b2;
  color: #fff;
}