.createPost .container {
    margin-left: auto;
    margin-right: auto;
    margin-top: 12rem; /* equivalent to Tailwind class 'mt-48' */
  }
  
  .createPost .block {
    display: block;
  }
  
  .createPost .inline-flex {
    display: inline-flex;
  }
  
  .createPost .mb-1 {
    margin-bottom: 0.25rem;
  }
  
  .createPost .mb-4 {
    margin-bottom: 1rem;
  }
  
  .createPost .my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .createPost .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .createPost .p-3 {
    padding: 0.75rem;
  }
  
  .createPost .p-4 {
    padding: 1rem;
  }
  
  .createPost .px-12 {
    padding-left: 3rem; /* equivalent to Tailwind class 'px-12' */
    padding-right: 3rem; /* equivalent to Tailwind class 'px-12' */
  }
  
  .createPost .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  
  .createPost .rounded-md {
    border-radius: 0.375rem; /* equivalent to Tailwind class 'rounded-md' */
  }
  
  .createPost .rounded-xl {
    border-radius: 0.75rem; /* equivalent to Tailwind class 'rounded-xl' */
  }
  
  .createPost .shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  
  .createPost .text-lg {
    font-size: 1.125rem;
  }
  
  .createPost .text-gray-500 {
    color: #6b7280; /* equivalent to Tailwind class 'text-gray-500' */
  }
  
  .createPost .text-gray-600 {
    color: #4b5563; /* equivalent to Tailwind class 'text-gray-600' */
  }
  
  .createPost .text-gray-700 {
    color: #4a5568; /* equivalent to Tailwind class 'text-gray-700' */
  }
  
  .createPost .text-primary-500 {
    color: #3b82f6; /* equivalent to Tailwind class 'text-primary-500' */
  }
  
  .createPost .text-primary-700 {
    color: #2563eb; /* equivalent to Tailwind class 'text-primary-700' */
  }
  
  .createPost .transition-all {
    transition-property: all;
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .createPost .w-10 {
    width: 2.5rem; /* equivalent to Tailwind class 'w-10' */
  }
  
  .createPost .w-full {
    width: 100%;
  }
  
  .createPost .h-10 {
    height: 2.5rem; /* equivalent to Tailwind class 'h-10' */
  }
  
  .createPost .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  .createPost .items-center {
    align-items: center;
  }
  
  .createPost .justify-center {
    justify-content: center;
  }
  
  .createPost .cursor-pointer {
    cursor: pointer;
  }
  
  .createPost .appearance-none {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  
  .createPost .border {
    border-width: 1px;
  }
  
  .createPost .border-dashed {
    border-style: dashed;
  }
  
  .createPost .border-gray-200 {
    border-color: #e5e7eb; /* equivalent to Tailwind class 'border-gray-200' */
  }
  
  .createPost .border-indigo-600 {
    border-color: #4f46e5; /* equivalent to Tailwind class 'border-indigo-600' */
  }
  
  .createPost .border-primary-300 {
    border-color: #93c5fd; /* equivalent to Tailwind class 'border-primary-300' */
  }
  
  .createPost .border-secondaryColor {
    border-color: #d1d5db; /* equivalent to Tailwind class 'border-secondaryColor' */
  }
  
  .createPost .border-solid {
    border-style: solid;
  }
  
  .createPost .rounded-full {
    border-radius: 9999px; /* equivalent to Tailwind class 'rounded-full' */
  }
  
  .createPost .bg-gray-100 {
    background-color: #f3f4f6; /* equivalent to Tailwind class 'bg-gray-100' */
  }
  
  .createPost .bg-indigo-600 {
    background-color: #4338ca; /* equivalent to Tailwind class 'bg-indigo-600' */
  }
  
  .createPost .hover\:bg-transparent:hover {
    background-color: #93c5fd !important;
  }
  
  .createPost .hover\:border-primary-300:hover {
    border-color: #93c5fd !important;
  }
  
  .createPost .hover\:text-indigo-600:hover {
    color: #7f9cf5 !important; /* equivalent to Tailwind class 'hover:text-indigo-600' */
  }
  
  .createPost .focus\:outline-none:focus {
    outline: 0;
    box-shadow: none;
  }
  
  .createPost .focus\:ring:focus {
    outline: 0;
    box-shadow: 0 0 0 3px rgba(126, 61, 255, 0.5); /* equivalent to Tailwind class 'focus:ring' */
  }
  
  .createPost .active\:text-indigo-500:active {
    color: #4f46e5 !important; /* equivalent to Tailwind class 'active:text-indigo-500' */
  }
  
  .createPost .hidden {
    display: none;
  }
  .createPost .text-white{
    color: #fff;
  }
  .createPost .my-5{
    margin-top: 20rem;
    margin-bottom: 20rem;
  }