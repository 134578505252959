/* Responsive styles */
.back h1{
    font-weight: bold;
}

@media (max-width: 1200px) {
    .back h1 {
      font-size: 50px;
    }
  }
  
  @media (max-width: 992px) {
    .back h1 {
      font-size: 40px;
    }
  }
  
  @media (max-width: 768px) {
    .back h1 {
      font-size: 35px;
    }
  }
  
  @media (max-width: 576px) {
    .back h1 {
      font-size: 30px;
      margin-top: 30px;
    }
    .back h2{
        margin-top: 30px;
    }
    
  }