.ProfileSide{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    overflow: auto;
    padding-top: 1rem;
}

@media (max-width: 768px) {
    .ProfileSide {
        display: none;
    }
}