

.FollowersCard .mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.FollowersCard .max-w-md {
    max-width: 28rem; /* 448px */
}

.FollowersCard .overflow-hidden {
    overflow: hidden;
}

.FollowersCard .rounded-lg {
    border-radius: 0.5rem; /* 8px */
}

.FollowersCard .bg-white {
    background-color: #ffffff;
}
.FollowersCard .mb-1{
    margin-bottom: 1rem;
}

.FollowersCard .shadow {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.06);
}

.FollowersCard .divide-y > :not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
}

.FollowersCard .divide-gray-100 > :not(:last-child) {
    border-bottom-color: #0069af; /* light gray */
}

.FollowersCard .py-2 {
    padding-top: 0.5rem; /* 8px */
    padding-bottom: 0.5rem; /* 8px */
}

.FollowersCard .px-4 {
    padding-left: 1rem; /* 16px */
    padding-right: 1rem; /* 16px */
}

.FollowersCard .flex {
    display: flex;
}

.FollowersCard .items-center {
    align-items: center;
}

.FollowersCard .py-4 {
    padding-top: 1rem; /* 16px */
    padding-bottom: 1rem; /* 16px */
}

.FollowersCard .mr-4 {
    margin-right: 1rem; /* 16px */
}

.FollowersCard .flex-1 {
    flex: 1 1 0%;
}

.FollowersCard .text-lg {
    font-size: 1.125rem; /* 18px */
    line-height: 1.75rem; /* 28px */
}

.FollowersCard .font-medium {
    font-weight: 500;
}

.FollowersCard .text-gray-900 {
    color: #1a202c; /* dark gray */
}

.FollowersCard .mt-1 {
    margin-top: 0.25rem; /* 4px */
}

.FollowersCard .text-sm {
    font-size: 0.875rem; /* 14px */
    line-height: 1.25rem; /* 20px */
}

.FollowersCard .text-gray-400 {
    color: #cbd5e0; /* light gray */
}

.FollowersCard .h-10 {
    height: 3.5rem; /* 80px */
}

.FollowersCard .w-10 {
    width: 3.5rem; /* 80px */
}

.FollowersCard .rounded-lg {
    border-radius: 0.5rem; /* 8px */
}

.FollowersCard .object-cover {
    object-fit: cover;
}
