@tailwind utilities;


.new{
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
  }
  .card {
    border: 1px solid #fff;
    background: black;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
    cursor: pointer;
  }
  
  .card-top-top h1 {
    font-size: 0.9rem;
    margin: 20px;
  }
  
  .card-top-top  img {
    width: 100%;
    height: 300px;
    object-fit: fill;
    background: black;

  }
  
  .card-bottom {
    margin: 10px 20px;
  }
