@tailwind utilities;



  
  .new{
    width: 70%;
    margin: 0 auto;
    margin-top: 50px;
  }
  .card {
    border: 1px solid #fff;
    background: black;
    border-radius: 8px;
    overflow: hidden;
    color: #fff;
    cursor: pointer;
  }
  
  .card-top h1 {
    font-size: 0.9rem;
    margin: 20px;
  }
  
  .card-top  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    background: black;
  }
  
  .card-bottom {
    margin: 10px 20px;
  }
