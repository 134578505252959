@tailwind utilities;

.coursesCard {
  padding: 50px 0;
}
.coursesCard .grid2 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}
.coursesCard .items {
  background-color: #fff;
  padding: 30px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.coursesCard .img {
  width: 100%;
  height: 250px;
  padding: 5px;
}
.coursesCard img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.coursesCard .text {
  margin-left: 15px;
  text-align: left;
}
.coursesCard .text h1 {
  font-size: 30px;
  font-weight: 500;
  line-height: 45px;
}
.coursesCard .rate {
  margin: 20px 0;
  color: #1e39b2;
}
.coursesCard .rate i {
  font-size: 13px;
  margin-right: 5px;
}
.coursesCard .details .dimg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}
.coursesCard .details .box {
  display: flex;
  align-items: center;
  color: grey;
}
.coursesCard .details span {
  color: #1e39b2;
  font-weight: 500;
  font-size: 14px;
}
.coursesCard .price {
  margin: 30px 0;
  background-color: #f8f8f8;
  padding: 10px;
}
.coursesCard h3 {
  font-weight: 500;
  color: #1e39b2;
}
.coursesCard .outline-btn {
  border: 1px solid #1e39b2;
  padding: 10px 20px;
  color: #1e39b2;
  background: none;
  border-radius: 5px;
  transition: background 0.3s, color 0.3s;
}
.coursesCard .outline-btn:hover {
  background: #1e39b2;
  color: #fff;
}
.online {
  text-align: center;
}
.online .box {
  box-shadow: 0 5px 25px -2px rgb(0 0 0 / 6%);
  background-color: #fff;
  padding: 30px 20px;
  transition: 0.5s;
}
.online .img {
  width: 80px;
  height: 80px;
  margin: auto;
  position: relative;
}
.online img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.online h1 {
  font-weight: 500;
  font-size: 20px;
  margin: 20px 0;
  line-height: 30px;
}
.online span {
  background-color: #f8f8f8;
  padding: 5px 20px;
  font-weight: 500;
  font-size: 15px;
  color: #1e39b2;
  border-radius: 5px;
}
.online .box .show {
  opacity: 0;
}
.online .box:hover {
  background-color: #1e39b2;
  border-radius: 5px;
  cursor: pointer;
}
.online .box:hover .show {
  opacity: 1;
  position: absolute;
  top: 0;
  left: 0;
}
.online .box:hover h1 {
  color: #fff;
}

/* Media Queries */
@media screen and (max-width: 1066px) {
  .coursesCard .grid2 {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .coursesCard .grid2 {
    grid-template-columns: 1fr;
  }
  .coursesCard .items {
    width: 100%;
    margin: 10px 0;
  }
  .coursesCard .img {
    height: auto;
  }
  .coursesCard .text h1 {
    font-size: 24px;
    line-height: 35px;
  }
  .coursesCard .details .dimg img {
    width: 40px;
    height: 40px;
  }
}

@media screen and (max-width: 480px) {
  .coursesCard .text h1 {
    font-size: 20px;
    line-height: 30px;
  }
  .coursesCard .details .dimg img {
    width: 30px;
    height: 30px;
  }
}
