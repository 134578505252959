.Profile{
    position: relative;
    display: grid;
    grid-template-columns: 18rem auto 20rem;
    gap: 1rem;
}


.Profile-center{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}



@media (max-width: 1200px) {
    .Profile{
      grid-template-columns: 17rem auto 15rem;
    }
  }
  
  
  @media (max-width: 1050px) {
    .Profile{
      grid-template-columns: 20rem auto ;
    }
  }
  
  @media (max-width: 768px) {
    .Profile {
      grid-template-columns:  auto  ;
    }
  }