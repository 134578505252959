.add .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .add .my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  .add .max-w-screen-sm {
    max-width: 768px	;
  }
  
  .add .block {
    display: block;
  }
  
  .add .text-lg {
    font-size: 1.125rem;
  }
  
  .add .font-medium {
    font-weight: 500;
  }
  
  .add .text-gray-700 {
    color: #4a5568;
  }
  
  .add .p-3 {
    padding: 0.75rem;
  }
  
  .add .border {
    border-width: 1px;
  }
  
  .add .rounded-md {
    border-radius: 0.375rem;
  }
  
  .add .border-gray-300 {
    border-color: #d1d5db;
  }
  
  .add .shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  
  .add .focus\:border-primary-400:focus {
    border-color: #6366f1;
  }
  
  .add .focus\:ring:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.5);
  }
  
  .add .focus\:ring-primary-200:focus {
    --tw-ring-color: rgba(199, 210, 254, 0.5);
  }
  
  .add .focus\:ring-opacity-50:focus {
    --tw-ring-opacity: 0.5;
  }
  
  .add .disabled\:cursor-not-allowed:disabled {
    cursor: not-allowed;
  }
  
  .add .disabled\:bg-gray-50:disabled {
    background-color: #f9fafb;
  }
  
  .add .disabled\:text-gray-500:disabled {
    color: #6b7280;
  }
  
  .add .text-base {
    font-size: 1rem;
  }
  
  .add .font-bold {
    font-weight: 700;
  }
  
  .add .cursor-pointer {
    cursor: pointer;
  }
  
  .add .appearance-none {
    appearance: none;
  }
  
  .add .items-center {
    align-items: center;
  }
  
  .add .justify-center {
    justify-content: center;
  }
  
  .add .border-2 {
    border-width: 2px;
  }
  
  .add .border-dashed {
    border-style: dashed;
  }
  
  .add .border-gray-200 {
    border-color: #e5e7eb;
  }
  
  .add .p-6 {
    padding: 1.5rem;
  }
  
  .add .transition-all {
    transition: all 0.2s;
  }
  
  .add .border-primary-300 {
    border-color: #a5b4fc;
  }
  
  .add .space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse));
  }
  
  .add .text-center {
    text-align: center;
  }
  
  .add .inline-flex {
    display: inline-flex;
  }
  
  .add .h-10 {
    height: 2.5rem;
  }
  
  .add .w-10 {
    width: 2.5rem;
  }
  
  .add .rounded-full {
    border-radius: 9999px;
  }
  
  .add .bg-gray-100 {
    background-color: #f3f4f6;
  }
  
  .add .text-gray-500 {
    color: #6b7280;
  }
  
  .add .text-gray-600 {
    color: #4b5563;
  }
  
  .add .text-primary-500 {
    color: #6366f1;
  }
  
  .add .hover\:text-primary-700:hover {
    color: #4f46e5;
  }
  
  .add .text-sm {
    font-size: 0.875rem;
  }
  
  .add .max-w-xs {
    max-width: 20rem;
  }
  
  .add .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }
  
  .add .inline-block {
    display: inline-block;
  }
  
  .add .w-full {
    width: 100%;
  }
  
  .add .rounded {
    border-radius: 0.375rem;
  }
  
  .add .border-indigo-600 {
    border-color: #4f46e5;
  }
  
  .add .bg-indigo-600 {
    background-color: #4f46e5;
  }
  
  .add .px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  
  .add .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  
  .add .text-white {
    color: #ffffff;
  }
  
  .add .hover\:bg-transparent:hover {
    background-color: transparent;
  }
  
  .add .hover\:text-indigo-600:hover {
    color: #4f46e5;
  }
  
  .add .focus\:outline-none:focus {
    outline: none;
  }
  
  .add .focus\:ring:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(99, 102, 241, 0.5);
  }
  
  .add .active\:text-indigo-500:active {
    color: #4338ca;
  }
  /* Styling for the file upload component */
.add.mx-auto.my-4 {
  max-width: 400px;
}

.add.mx-auto {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add label {
  margin-bottom: 1rem;
}

.add .flex {
  display: flex;
  flex-wrap: wrap;
}

.add .space-y-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.add .inline-flex {
  display: inline-flex;
}

.add .h-10,
.add .w-10 {
  height: 2.5rem;
  width: 2.5rem;
}

.add .text-gray-500 {
  color: #6b7280;
}

.add input[type='file'] {
  display: none;
}

/* Styling for image previews */
.add .relative {
  position: relative;
}

 .w-32,
 .h-32 {
  width: 8rem;
  height: 8rem;
}

.add .m-2 {
  margin: 0.5rem;
}

.add .object-cover {
  object-fit: cover;
}

.add .bg-red-500 {
  background-color: #dc2626;
}

.add .text-white {
  color: #fff;
}

.add .p-1 {
  padding: 0.25rem;
}

.add .rounded-full {
  border-radius: 9999px;
}

.delete{
  position: absolute;
  top: -1rem;
  right: 0;
  padding: 1rem;
}

.my-6{
  margin-top: 3rem;
  margin-bottom: 1rem;
}
