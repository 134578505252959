@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;0,700;0,800;1,600&display=swap');


/* variables */


:root {
  --clr-primary: #7380ec;
  --clr-danger: #ff7782;
  --clr-success: #41f1b6;
  --clr-white: #fff;
  --clr-info-dark: #7d8da1;
  --clr-info-light: #dce1eb;
  --clr-dark: #363949;
  --clr-warnig: #ff4edc;
  --clr-light: rgba(132, 139, 200, 0.18);
  --clr-primary-variant: #111e88;
  --clr-dark-variant: #677483;
  --clr-color-background: #f6f6f9;

  --card-border-radius: 2rem;
  --border-radius-1: 0.4rem;
  --border-radius-2: 0.8rem;
  --border-radius-3: 1.2rem;

  --card-padding: 1.8rem;
  --padding-1: 1.2rem;
  --box-shadow: 0 2rem 3rem var(--clr-light);

}

/* dark theme */
.dark-theme-variables {
  --clr-color-background: #181a1e;
  --clr-white: #202528;
  --clr-light: rgba(0, 0, 0, 0.4);
  --clr-dark: #edeffd;
  --clr-dark-variant: #677483;
  --box-shadow: 0 2rem 3rem var(--clr-light)
}






.Dashboardcontainer  {
  display: grid;
  width: 96%;
  gap: 1.8rem;
  grid-template-columns: 14rem auto 16rem;
  margin: 0 auto;
}


.profile-photo img{
  width: 2.8rem;
  height: 2.8rem;
  overflow: hidden;
  border-radius: 50%;
}
.text-muted{
  color: var(--clr-info-dark);
}

.primary {
  color: var(--clr-primary);
}

.success {
  color: var(--clr-success);
}

.danger {
  color: var(--clr-danger);
}

.warning {
  color: var(--clr-warnig);
}


/* aside */
 aside{
  height: 100vh;
 }
 aside .top{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.4rem;
 }

 aside .logo{
   display: flex;
   gap: 1rem;
 }
 aside .logo img{
   width: 2rem;
   height: 2rem;

 }
 aside .top div.close span{
   display: none;
 }

 /* ===================
 Sidebar
 ================== */

 aside .sidebar{
  background: var(--clr-white);
  display: flex;
  flex-direction: column;
  height: 86vh;
  position: relative;
  top: 1rem;
 }

 aside h3{
  font-weight: 500;
 }

 


 







 aside .sidebar button{
  display: flex;
  color: var(--clr-info-dark);
  gap: 1rem;
  align-items: center;
  height: 3.3rem;
  transition: all .1s ease;
 
 }

 aside .sidebar button span{
  font-size: 1.6rem;
  transition: all .3s ease-in-out;
  
 }

 aside .sidebar button:last-child{
  position: absolute;
  bottom: 1rem; 
  width: 100%;
 }

 aside .sidebar button.active{
   background-color: var(--clr-light);
   color: var(--clr-primary);
   margin-left: 0;
   border-left: 5px solid var(--clr-primary);
 }
 aside .sidebar button:hover span{
  margin-left: 1rem;
 }

 aside .sidebar button span.msg_count{
   background-color: var(--clr-danger);
   color: var(--clr-white);
   padding: 2px 5px;
   font-size: 11px;
   border-radius: var(--border-radius-1);
 }

 /* ----------------------------
 --------- Main--------------
 ------------------------------ */

 main{
  margin-top: 1.4rem;
  width: auto;
 }
 main input {
  background-color: transparent;
  border: 0;
  outline: 0;
  color: var(--clr-dark);
 }

 main .date{
  display: inline-block;
  background: var(--clr-white);
  border-radius: var(--border-radius-1);
  margin-top: 1rem;
  padding: 0.5rem 1.6rem;
 }

main .insights{
   display: grid;
   grid-template-columns: repeat(3,1fr);
   gap: 1.6rem;
}
main .insights>div{
   background-color: var(--clr-white);
   padding: var(--card-padding);
   border-radius: var(--card-border-radius);
   margin-top: 1rem;
   box-shadow: var(--box-shadow);
   transition: all .3s ease;
}

main .insights > div:hover{
  box-shadow: none;
}
main .insights>div  span{
   background: coral;
    padding: 0.5rem;
     border-radius: 50%;
     color: var(--clr-white);
     font-size: 2rem; 
  }

main .insights > div.expenses span{
   background: var(--clr-danger);
}
main .insights > div.income span{
  background: var(--clr-success);
}

main .insights > div .middle{
   display: flex;
   align-items: center;
   justify-content: space-between;
}
main .insights > div .middle h1{
  font-size: 1.6rem;
}

main h1{
  color: var(--clr-dark);
}
main .insights h1{
  color: var(--clr-dark);
}
main .insights h3{
  color: var(--clr-dark);
}
main .insights p{
  color: var(--clr-dark);
}
main .insights h3{
  
    color: var(--clr-dark);
  
  
}

main .insights .progress{
  position: relative;
  height: 68px;
  width: 68px;
  border-radius: 50px;
}

main .insights svg{
  height: 150px;
  width: 150px;
  position: absolute;
  top: 0;
}

main .insights svg circle{
  fill: none;
  stroke: var(--clr-primary);
   transform: rotate(270,80,80);
   stroke-width: 5;
}
main .insights .sales svg circle{
   stroke-dashoffset: 0;
   stroke-dasharray: 150;
}

main .insights .expenses svg circle{
  stroke-dashoffset: 10;
  stroke-dasharray: 150;
}
main .insights .sales svg circle{
  stroke-dashoffset: 0;
  stroke-dasharray: 150;
}
main .insights .income svg circle{
  stroke: var(--clr-success);
}

main .insights .progress .number{
  position: absolute;
  top: 5%;
  left: 5%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* recent order */

main .recent_order{
   margin-top: 2rem;
}

main .recent_order h2{
    color: var(--clr-dark);
}

main .recent_order h2{
  margin-bottom: 0.8rem;
}
main .recent_order table{
  background: var(--clr-white);
  width: 100%;
  border-radius: var(--card-border-radius);
  padding: var(--card-padding);
  text-align: center;
  box-shadow: var(--box-shadow);
  transition: all .3s ease;
  color: var(--clr-dark);
}
main .recent_order table:hover{
  box-shadow: none;
}

main table tbody td{
   height: 3.8rem;
   border-bottom: 1px solid var(--clr-white);
   color:var(--clr-dark-variant)
}
main table tbody tr:last-child td{
   border: none;
}


main .recent_order a{
  text-align: center;
  display: block;
  margin: 1rem;
}


/*********************
Right b
**********************/

.right{
  margin-top: 1.4rem;
}
.right h2{
  color: var(--clr-dark);
}
.right .top{
  display: flex;
  justify-content: start;
  gap: 2rem;
}

.right .top button{
  display: none;
}

.right .theme-toggler{
  background: var(--clr-white);
  display: flex;
  justify-content: space-between;
  height: 1.6rem;
  width: 4.2rem;
  cursor: pointer;
  border-radius: var(--border-radius-1);
}
.right .theme-toggler span{
  font-size: 1.2rem;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right .theme-toggler span.active{
  background: var(--clr-primary);
  color: #fff;
}


.right .top .profile{
  display: flex;
  gap: 2rem;
  text-align: right;
}
.right  .info h3{
  color: var(--clr-dark);
}
.right  .item h3{
  color: var(--clr-dark);
}

/* recent update */

.right .recent_updates{
  margin-top: 1rem;
  margin-left: -20px;
}
.right .recent_updates .updates{
  background-color: var(--clr-white);
  padding: var(--card-padding);
  border-radius: var(--card-border-radius);
  box-shadow: var(--box-shadow);
   transition: all .3s ease;
}
.right .recent_updates .updates:hover{
  box-shadow: none;
}
.right .recent_updates .update{
   display: grid;
   grid-template-columns: 2.6rem auto;
   gap: 1rem;
   margin-bottom: 1rem;
}



/* see analytics */

.right .sales-analytics{
  margin-top: 2rem;
}
.right .sales-analytics h2{
  margin-bottom: 0.8rem;
}
.right .sales-analytics .item{
  background-color: var(--clr-white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin: 10px 0;
  width: 100%;
  margin-bottom: 0.8rem;
  padding: 1.4rem var(--card-padding);
  border-radius: var(--border-radius-3);
  box-shadow: var(--box-shadow);
}
.right .sales-analytics .item:hover{
  box-shadow: none;
}

.right .sales-analytics .item .icon{
  padding: 0.6rem;
  color: var(--clr-white);
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 50px;
  align-items: center;
  background: coral;
}
.right .sales-analytics .item:nth-child(3) .icon
{
  background: var(--clr-success);
}
.right .sales-analytics .item:nth-child(4) .icon
{
  background: var(--clr-danger);
}

.right .sales-analytics .item .right{
  margin-left: 15px;
  display: flex;
  justify-content: space-around;
}
.right .sales-analytics .item .right h3{
  color: var(d);
}


.add_product div{
  display: flex;
  height: 60px;
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  border: 2px dashed;
  color: var(--clr-dark-variant);
  margin-bottom: 40px;

}




/* **********8
MEDIA QUERY
****************/

@media screen and (max-width:1200px) {
    .Dashboardcontainer{
      width: 94%;
      grid-template-columns: 7rem auto 14rem;
    }
    aside .sidebar h3{
      display: none;
    }
    

    aside .sidebar button{
      width: 5.6rem;
    }
    aside .sidebar button:last-child{
      position: relative;
      margin-top: 1.8rem;
    }

    aside .sidebar button{
      width: 5.6rem;
    }
    
    main .insights{
      display: grid;
      grid-template-columns: repeat(1,1fr);
    }
}

/* ****************
MEDIA QUERY PHONE
************************/

@media screen and (max-width:768px) {
  .Dashboardcontainer{
    width: 100%;
    grid-template-columns: repeat(1,1fr);
  
  }

  .margini{
    margin-top: 10rem;
  }
  
  aside{
    position: fixed;
    width: 18rem;
    z-index: 3;
    height: 100vh;
    background-color: var(--clr-white);
    display: none;
    left: -110px;
    animation:  menuLeft .3s ease forwards;
  }
  
  @keyframes menuLeft {
      to{
        left: 0;
      }
  }
  



  aside .logo h2{
       display: inline;
  }
  aside .sidebar h3{
    display: inline;
  }
  

  aside .sidebar button{
    width: 100%;
    height: 3.4rem;
  }
  aside .top div.close  span{
     display: inline;
     position: absolute;
     right: 0;
     margin-right:30px;
     font-size: 35px;
     cursor: pointer;
  }

    .right .top{
       position: fixed;
       top: 0;
       left: 0;
       display: flex;
       justify-content: center;
       align-items: center;
       padding: 0 0.8rem;
       background: var(--clr-white);
       height: 4.6rem;
       width: 100%;
       z-index: 2;
       box-shadow: 0 1rem 1rem var(--clr-light);
       margin: 0;
    }


 main .insights{
  display: grid;
  grid-template-columns: repeat(1,1fr);
  gap: 1.6rem;
  padding: 40px;
 }


 main .recent_order{
    margin-top: 2rem;
 }
 
 main .recent_order h2{
     color: var(--clr-dark);
 }
 
 main .recent_order h2{
   margin-bottom: 0.8rem;
 }
 main .recent_order table{
   background: var(--clr-white);
   width: 100%;
   border-radius: var(--card-border-radius);
   padding: var(--card-padding);
   text-align: center;
   box-shadow: var(--box-shadow);
   transition: all .3s ease;
   color: var(--clr-dark);
 }
 main .recent_order table:hover{
   box-shadow: none;
 }
 

 



  .right .profile{
    position: absolute;
    left: 70%;
  }

  .right .top .theme-toggler{
    width: 4.4rem;
    position: absolute;
    left: 50%;
  }
   .right .profile .info{
    display: none;
   }

   .right .top button{
     display: inline-block;
     background: transparent;
     cursor: pointer;
     color: var(--clr-dark);
     position: absolute;
     left: 1rem;

   }
   .right .recent_order{
    padding: 0 30px;
   }
   .right .recent_updates{
    padding: 0 40px;
   }
   .right .sales-analytics{
    padding: 0 40px;
   }
   .right .add_product{
    padding: 0 40px;
    margin-bottom: 40px;
   }


}


