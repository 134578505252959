.PostSide
{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100vh;
    overflow: auto;;
    padding-top: 1rem;
}

@media (max-width: 768px) {
    .PostSide {
        padding-top: 0rem;
    }
}