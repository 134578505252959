.LogoSearch {
  display: flex;
  gap: 0.75rem;
}
.Search {
  display: flex;
  background-color: var(--inputColor);
  border-radius: 10px;
  padding: 5px;
}
.Search>input {
  background-color: transparent;
  border: none;
  outline: none;
}
.s-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(106.23deg, #300bff 0%, #3573f9 100%);
  border-radius: 5px;
  padding: 4px;
  color: white;
}

@media screen and (max-width: 768px) {
  .searchButton{
    display: none;
  }
}
