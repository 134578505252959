@tailwind utilities;

.product .max-w-7xl {
    max-width: 80rem; /* 7xl */
  }
  
  .product .grid {
    display: grid;
  }
  
  .product .grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  
  .product .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  
  .product .gap-10 {
    gap: 2.5rem; /* 10 */
  }
  
  .product .lg\:place-items-center {
    align-items: center;
    justify-items: center;
  }
  
  .product .lg\:py-20 {
    padding-top: 5rem; /* 20 */
    padding-bottom: 5rem; /* 20 */
  }
  
  .product article {
    display: block;
  }
  
  .product .px-8 {
    padding-left: 2rem; /* 8 */
    padding-right: 2rem; /* 8 */
  }
  
  .product .pb-10 {
    padding-bottom: 2.5rem; /* 10 */
  }
  
  .product .bg-slate-100 {
    background-color: #f8fafc; /* slate-100 */
  }
  
  .product .py-1 {
    padding-top: 0.25rem; /* 1 */
    padding-bottom: 0.25rem; /* 1 */
  }
  
  .product .px-2 {
    padding-left: 0.5rem; /* 2 */
    padding-right: 0.5rem; /* 2 */
  }
  
  .product .text-orange-400 {
    color: #061491; /* orange-400 */
  }
  
  .product .uppercase {
    text-transform: uppercase;
  }
  
  .product .tracking-wide {
    letter-spacing: 0.05em; /* wide */
  }
  
  .product .font-bold {
    font-weight: 700;
  }
  
  .product .inline-block {
    display: inline-block;
  }
  
  .product .rounded {
    border-radius: 0.375rem; /* rounded */
  }
  
  .product .shadow {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05); /* shadow */
  }
  
  .product .mb-10 {
    margin-bottom: 2.5rem; /* 10 */
  }
  
  .product .text-slate-900 {
    color: #1a202c; /* slate-900 */
  }
  
  .product .text-3xl {
    font-size: 1.875rem; /* 3xl */
  }
  
  .product .lg\:text-4xl {
    font-size: 2.25rem; /* 4xl */
  }
  
  .product .text-slate-600 {
    color: #718096; /* slate-600 */
  }
  
  .product .leading-relaxed {
    line-height: 1.625; /* relaxed */
  }
  
  .product .flex {
    display: flex;
  }
  
  .product .flex-wrap {
    flex-wrap: wrap;
  }
  
  .product .lg\:flex-col {
    flex-direction: column;
  }
  
  .product .items-center {
    align-items: center;
  }
  
  .product .justify-between {
    justify-content: space-between;
  }
  
  .product .bg-orange-100 {
    background-color: #fffaf0; /* orange-100 */
  }
  
  .product .py-2 {
    padding-top: 0.5rem; /* 2 */
    padding-bottom: 0.5rem; /* 2 */
  }
  
  .product .px-4 {
    padding-left: 1rem; /* 4 */
    padding-right: 1rem; /* 4 */
  }
  
  .product .lg\:flex-1 {
    flex: 1;
  }
  
  .product .w-full {
    width: 100%;
  }
  
  .product .mt-10 {
    margin-top: 2.5rem; /* 10 */
  }
  
  .product .lg\:gap-2 {
    gap: 0.5rem; /* 2 */
  }
  
  .product .text-slate-900 {
    color: #1a202c; /* slate-900 */
  }
  
  .product .text-2xl {
    font-size: 1.5rem; /* 2xl */
  }
  
  .product .bg-orange-500 {
    background-color: #250b6d; /* orange-500 */
  }
  
  .product .text-white {
    color: #fff;
  }
  
  .product .rounded-lg {
    border-radius: 0.5rem; /* rounded-lg */
  }
  
  .product .hover\:bg-orange-600:hover {
    background-color: #2033dd; /* orange-600 */
  }
  
  .product .transition-all {
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 150ms;
  }
  
  .product .duration-200 {
    transition-duration: 200ms;
  }
  
  .product .cursor-pointer {
    cursor: pointer;
  }
  
  .product .opacity-80 {
    opacity: 0.8;
  }
  
  .product .border-2 {
    border-width: 2px;
  }
  
  .product .border-orange-400 {
    border-color: #132ea3; /* orange-400 */
  }
  
  .product .rounded-2xl {
    border-radius: 1rem; /* rounded-2xl */
  }
  
  .product .overflow-hidden {
    overflow: hidden;
  }
  
  .product .cursor-pointer {
    cursor: pointer;
  }
  