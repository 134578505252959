.TrendCard{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    background-color: var(--cardColor);
    padding: 1rem;
    border-radius: 1rem;
    padding-left: 2rem;
}


.interest{
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    align-items: center;
}


.interest>span:nth-of-type(1)
{
    font-weight: bold;
}
.interest>span:nth-of-type(2)
{
    font-size: 13px;
    font-weight:bold;
    color: rgb(0, 81, 255);
}
.starspan{
    display: flex;
    align-items: center;
}

.Head{
    display: flex;
    align-items: center;
    gap: 1rem;
}


.TrendCard .star{
    font-size: 20px;
    color: rgb(0, 81, 255);
}
/* Height and Width */
.interest img {
    width: 40px; /* Adjust as needed */
    height: 40px; /* Adjust as needed */
    border-radius: 50%; /* Makes the image round */
    object-fit: cover; /* Ensures the image covers the entire space */
    border: 2px solid #fff; /* Optional: Add a border */
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow */
  }