
.Doctors .container{
    max-width: 1100px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    padding: 20px;
  }
  .Doctors .container .box{
    width: calc(33% - 10px);
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 30px;
    border-radius: 5px;
  }
  .Doctors .box .quote i{
  margin-top: 10px;
  font-size: 45px;
  color: #17c0eb
  }
  .Doctors .container .box .image{
    margin: 10px 0;
    height: 150px;
    width: 150px;
    background: #0e2aa7;
    padding: 3px;
    border-radius: 50%;
  }
  .Doctors .box .image img{
    height: 100%;
    width: 100%;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #fff;
  }
  .Doctors .box p{
    text-align: justify;
    margin-top: 8px;
    font-size: 16px;
    font-weight: 400;
  }
  .Doctors .box .name_job{
    margin: 10px 0 3px 0;
    color: #0e2aa7;
    cursor: pointer;
    font-size: 18px;
    font-weight: 600;
  }
  .Doctors .box .username{
    margin: 10px 0 3px 0;
    color: #515c8d;
    font-size: 18px;
    font-weight: 600;
  }
  .Doctors .rating i{
    font-size: 18px;
    color: #0e2aa7;
    margin-bottom: 5px;
  }
  .Doctors .btns{
    margin-top: 20px;
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .Doctors .btns button{
    background: #0e2aa7;
    width: 100%;
    padding: 9px 0px;
    outline: none;
    border: 2px solid #0e2aa7;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    color: #0e2aa7;
    transition: all 0.3s linear;
  }
  .Doctors .btns button:first-child{
    background: none;
    margin-right: 5px;
  }
  .Doctors .btns button:last-child{
    color: #fff;
    margin-left: 5px;
  }
  .Doctors .btns button:first-child:hover{
    background: #0e2aa7;
    color: #fff;
  }
  .Doctors .btns button:hover{
    color: #fff;
  }
  .Doctors .find{
    text-align: center;
    margin-top: 100px;
    margin-bottom: 20px;
    font-size: 50px;
  }
  

.Doctor-form {
    width: 100%;
}

.Doctor-label {
    margin-bottom: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
    color: #1f2937; /* text-gray-900 */
    display: none; /* sr-only */
}

.Doctor-relative {
    position: relative;
}

.Doctor-absolute {
    position: absolute;
}

.Doctor-inset-y-0 {
    top: 0;
    bottom: 0;
}

.Doctor-start-0 {
    left: 0;
}

.Doctor-flex {
    display: flex;
}

.Doctor-items-center {
    align-items: center;
}

.Doctor-pointer-events-none {
    pointer-events: none;
}

.Doctor-svg {
    width: 1rem; /* 4 */
    height: 1rem; /* 4 */
    color: #6b7280; /* text-gray-500 */
    /* dark mode */
    .dark & {
        color: #9ca3af; /* text-gray-400 */
    }
}

.Doctor-input {
    display: block;
    width: 100%;
    background-color: rgba(var(--color-primary), 0.3); /* bg-primaryColor/30 */
    padding: 1rem; /* 4 */
    padding-left: 2.5rem; /* ps-10 */
    font-size: 0.875rem; /* text-sm */
    color: #1f2937; /* text-gray-900 */
    border: 1px solid #d1d5db; /* border-gray-300 */
    border-radius: 0.375rem; /* rounded-md */
    background-color: #f9fafb; /* bg-gray-50 */
    &:focus {
        outline: none;
        ring: 0.5rem; /* 4 */
        border-color: #3b82f6; /* focus:border-blue-500 */
        ring-color: #bfdbfe; /* focus:ring-blue-500 */
    }
    /* dark mode */
    .dark & {
        background-color: #374151; /* bg-gray-700 */
        border-color: #4b5563; /* dark:border-gray-600 */
        placeholder-color: #9ca3af; /* dark:placeholder-gray-400 */
        color: #ffffff; /* dark:text-white */
        &:focus {
            ring-color: #3b82f6; /* dark:focus:ring-blue-500 */
            border-color: #3b82f6; /* dark:focus:border-blue-500 */
        }
    }
}

.Doctor-button {
    color: #ffffff; /* text-white */
    position: absolute;
    right: 0.625rem; /* end-2.5 */
    bottom: 0.625rem; /* bottom-2.5 */
    background-color: #1d4ed8; /* bg-blue-700 */
    &:hover {
        background-color: #1e40af; /* hover:bg-blue-800 */
    }
    &:focus {
        ring: 0.25rem; /* focus:ring-4 */
        outline: none; /* focus:outline-none */
        ring-color: #bfdbfe; /* focus:ring-blue-300 */
    }
    font-weight: 500; /* font-medium */
    border-radius: 0.375rem; /* rounded-lg */
    font-size: 0.875rem; /* text-sm */
    padding: 0.5rem 1rem; /* px-4 py-2 */
    /* dark mode */
    .dark & {
        background-color: #2563eb; /* dark:bg-blue-600 */
        &:hover {
            background-color: #1e3a8a; /* dark:hover:bg-blue-700 */
        }
        &:focus {
            ring-color: #1e40af; /* dark:focus:ring-blue-800 */
        }
    }
}

  
  @media (max-width:1045px){
    .Doctors .container .box{
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }
  }
  @media (max-width:710px){
    .Doctors .container .box{
      width: 100%;
    }
  }

.InterestDiv{
  
  display: inline-block;
  outline: 0;
  text-align: center;
  cursor: pointer;
  padding: 0px 16px;
  border-radius: 2px;
  min-width: 80px;
  height: 32px;
  background-color: rgb(0, 212, 124);
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 400;
  box-sizing: border-box;
  border: 1px solid rgb(0, 212, 88);
  

}