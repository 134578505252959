.testimonal .items {
  padding: 30px;
  transition: 0.5s;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonal .img {
  position: relative;
}
.testimonal img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;

}
.testimonal i {
  position: absolute;
  bottom: 0;
  left: 60px;
  background-color: #1e39b2;
  color: #fff;
  padding: 5px;
  border-radius: 50%;
}
.testimonal .name {
  margin: 25px;
}
.testimonal .name h2 {
  font-size: 20px;
  margin-bottom: 5px;
}
.testimonal .name span {
  color: #1e39b2;
}
.testimonal p {
  margin-top: 20px;
  color: grey;
  transition: 0.5s;
}
.testimonal .items:hover {
  background-color: #1e39b2;
  color: #fff;
  cursor: pointer;
}
.testimonal .items:hover span,
.testimonal .items:hover p {
  color: #fff;
}
.testimonal .items:hover i {
  background-color: #fff;
  color: #1e39b2;
}

/* Media Queries */
@media screen and (max-width: 1066px) {
  .testimonal .content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
}

@media screen and (max-width: 768px) {
  .testimonal .content {
    grid-template-columns: 1fr;
  }
  .testimonal .name h2 {
    font-size: 18px;
  }
  .testimonal .name span {
    font-size: 14px;
  }
  .testimonal p {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  .testimonal .name h2 {
    font-size: 16px;
  }
  .testimonal .name span {
    font-size: 12px;
  }
  .testimonal p {
    font-size: 12px;
  }
}
