.infoForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .infoForm h3 {
    text-align: center;
  }
  
  .infoInput {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .infoButton {
    width: 100%;
    padding: 0.7rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .infoButton:hover {
    background-color: #0056b3;
  }
  
  @media (min-width: 700px) {
    .infoForm div {
      display: flex;
      gap: 1rem;
    }
  
    .infoInput {
      flex: 1;
    }
  
    .infoButton {
      width: auto;
      align-self: flex-end;
    }
  }
  
  @media (max-width: 599px) {
    .infoForm div {
      display: flex;
      flex-direction: column;
    }
    .infoForm .spesitalisation{
      margin-top: 3rem;
    }
  
    .infoInput {
      width: 100%;
    }
  
    .infoButton {
      width: 100%;
    }
  }
  