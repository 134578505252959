.navIconsPosts .pointer {
    cursor: pointer;
    text-decoration: none;
    text-transform: none;
    color: black;
}
.navIconsPosts{
    display: none;
}

.navIconsPosts>a>img{
    width: 1.5rem;
    height: 1.5rem;
}

.navIconsPosts>img{
    width: 1.5rem;
    height: 1.5rem;
}

.navIconsPosts>img:hover{
    cursor: pointer;
}



@media screen and (max-width: 1024px) {
    .navIconsPosts {
        bottom: 0; /* Adjusted from 30px to 0 to place it at the bottom */
        left: 0; 
        right: 0; 
        position: fixed;
        background: #fff;
        padding: 1rem;
        box-shadow: 10px 5px 5px black;    
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        padding-bottom: 2rem;
    }
}

